import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { addHours, subDays } from 'date-fns';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useAuth } from '../../auth/index.js';
import { DEBUG_SKIP_LEDEN } from '../../config/index.js';
import {
  CHECK_UNREAD_BERICHTEN,
  CREATE_BERICHTEN,
  GET_BEDELING,
  GET_BEDELING_LID_AANWEZIGHEDEN,
  GET_BERICHTEN,
  GET_LEDEN,
  SET_BERICHT_READ,
  UPDATE_LID,
} from '../../graphql/index.js';
import ActivityIndicator from '../common/ActivityIndicator';
import Button from '../common/Button.js';
import LoadingDiv from '../common/LoadingDivModal';
import PopupModal from '../common/PopupModal.js';
import UnstyledButton from '../common/UnstyledButton.js';
import { Message } from '../messages/message.jsx';
import SearchBox from '../SearchBox';
import BedelingTableHeader from './BedelingTableHeader.js';
import { Inventaris } from './inventaris.js';
import LidRow from './LidRow';

const dateOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const TopTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  margin: 1rem 0;
`;
const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Page = styled.div`
  @media only screen and (min-width: 600px) {
    padding: 1rem;
  }
  margin-bottom: 75px;

  padding-top: 3rem;
`;

const TableContainer = styled.div`
  margin-top: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MessageCounter = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  line-height: 2rem;
`;

const Arrows = styled.span`
  display: flex;
  gap: 1rem;
  & > * {
    padding: 0.5rem;
    font-size: 3rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
`;

const StyledPopupModal = styled(PopupModal)``;

const initialValues = {
  datum: new Date().toLocaleDateString('nl-BE', dateOptions),
  verantwoordelijke: 'fre',
  medewerker: '',
  kleinPuppy: '',
  mediumPuppy: '',
  grootPuppy: '',
  kleinVolwassen: '',
  mediumVolwassen: '',
  grootVolwassen: '',
  kleinSenior: '',
  mediumSenior: '',
  grootSenior: '',
  kitten: '',
  volwassenKat: '',
  seniorKat: '',
  kattenzand: '',
  speciallekes: '',
  opmerkingen: '',
};

const BedelingAfsluitenSchema = Yup.object().shape({
  datum: Yup.string().required('Datum is verplicht'),
  verantwoordelijke: Yup.string().required('Verantwoordelijke is verplicht'),
  medewerker: Yup.string(),
  kleinPuppy: Yup.string().required('Klein puppy is verplicht'),
  mediumPuppy: Yup.string().required('Medium puppy is verplicht'),
  grootPuppy: Yup.string().required('Groot puppy is verplicht'),
  kleinVolwassen: Yup.string().required('Klein volwassen is verplicht'),
  mediumVolwassen: Yup.string().required('Medium volwassen is verplicht'),
  grootVolwassen: Yup.string().required('Groot volwassen is verplicht'),
  kleinSenior: Yup.string().required('Klein senior is verplicht'),
  mediumSenior: Yup.string().required('Medium senior is verplicht'),
  grootSenior: Yup.string().required('Groot senior is verplicht'),
  kitten: Yup.string().required('Kitten is verplicht'),
  volwassenKat: Yup.string().required('Volwassen kat is verplicht'),
  seniorKat: Yup.string().required('Senior kat is verplicht'),
  kattenzand: Yup.string().required('Kattenzand is verplicht'),
  speciallekes: Yup.string(),
  opmerkingen: Yup.string(),
});

const DeletedUserWarning = styled.div`
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  & i {
    font-size: 2rem;
    margin-right: 1rem;
    color: #e85a1e;
  }
`;

const SelectedBericht = styled.span`
  font-weight: 900;
  color: #e85a1e;
`;

const BedelingView = ({ match }) => {
  const [leden, setLeden] = React.useState([]);
  const [searchString, setSearchString] = React.useState('');
  const { currentUser } = useAuth();
  const [showBericht, setShowBericht] = React.useState(false);
  const [showAfsluiten, setShowAfsluiten] = React.useState(false);

  const { loading: loadingBedeling, data: { bedelingen: bedeling } = {} } =
    useQuery(GET_BEDELING, {
      variables: { id: match.params.id },
    });

  const bedelingDatum = bedeling ? new Date(bedeling.datum) : undefined;

  const endOfLastBedeling = bedeling
    ? addHours(subDays(bedelingDatum, 7), bedeling.durationInHours || 2)
    : undefined;

  const { loading: loadingBerichten, data: dataBerichten } = useQuery(
    GET_BERICHTEN,
    {
      pollInterval: 5000,
      skip: !bedeling,
      variables: {
        where: {
          _sort: 'created_at:desc',
          type: 'toBedelingsploeg',
          created_at_gt: endOfLastBedeling,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // if not all messages are read, show the popup
        const myUnreadMessages = data?.berichtens?.filter(
          (bericht) =>
            !bericht.readByUsers
              .map((user) => user.id)
              .includes(currentUser?.id),
        );
        if (myUnreadMessages.length > 0) {
          setShowBericht(true);
        }
      },
    },
  );
  const berichten = dataBerichten?.berichtens;
  const [selectedBericht, setSelectedBericht] = React.useState(0);

  const bericht = dataBerichten?.berichtens[selectedBericht];

  const isAllBerichtenReadByCurrentUser = berichten?.every((bericht) =>
    bericht.readByUsers.map((user) => user.id).includes(currentUser?.id ?? ''),
  );

  const isBerichtReadByCurrentUser = bericht?.readByUsers
    .map((user) => user.id)
    .includes(currentUser?.id ?? '');

  const hasMoreThanOneUnreadMessage =
    berichten?.filter(
      (bericht) =>
        !bericht.readByUsers.map((user) => user.id).includes(currentUser?.id),
    ).length > 1;

  const berichtCanBeHidden = !bericht || isAllBerichtenReadByCurrentUser;

  const [markAsRead, { loading: loadingMarkAsRead }] = useMutation(
    SET_BERICHT_READ,
    {
      variables: { messageId: bericht?.id },
      refetchQueries: [
        { query: CHECK_UNREAD_BERICHTEN },
        // { query: GET_BERICHTEN },
      ],
      onError: (error) => {
        console.error(error);
      },
      onCompleted: () => {
        goToNextUnreadMessage();
      },
    },
  );

  const { loading: loadingLeden, data: dataLeden } = useQuery(GET_LEDEN, {
    // variables:{locatie:bedeling?.locatie.id}, // TODO: add locatie to GET_LEDEN query
    pollInterval: 5000,
  });

  const { data: dataAanwezigheden, loading: loadingAanwezigheden } = useQuery(
    GET_BEDELING_LID_AANWEZIGHEDEN,
    {
      variables: {
        bedelingId: match.params.id,
      },
    },
  );

  const ledenBackup = React.useMemo(() => {
    return dataLeden?.ledens
      ?.filter(
        (lid) => lid.locatie.id == bedeling?.locatie.id, // explicit == to coerce
      )
      .reverse()
      .sort((a, b) => {
        if (a.actief) {
          return -1;
        }
        if (b.actief) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      .map((lid) => {
        const augmentedLid = Object.create(lid);
        const nonetext = [
          lid.address,
          lid.postcode,
          lid.city,
          lid.email,
          lid.sinds,
          lid.bevestigd,
        ]
          .map((x) => x || '???')
          .join(' ');
        const dierennamen = lid?.huisdieren
          .map((x) => x?.naam)
          .reduce((acc, x) => acc + ' ' + x, '');
        const dierenopmerkingen = lid?.huisdieren
          .filter((x) => x?.opmerking)
          .map((x) => {
            return { text: x?.naam + ': ' + x?.opmerking, id: 'dopm' + x.id };
          });

        const ooitgeweest =
          lid?.aanwezigheden?.filter((x) => x?.aanwezig).length > 0;
        const actieftext = lid.actief
          ? 'wel actief:ja'
          : 'inactief niet actief:nee';
        const geweesttext = ooitgeweest ? 'al geweest:ja' : 'nooit geweest:nee';
        const dierentypes = [
          ...new Set(
            lid?.huisdieren.map((dier) => {
              const typeKort = dier.type === 5 ? dier.andere : dier.type?.kort;
              const leeftijd = dier.andere ? '' : dier.leeftijd?.kort;

              return (
                dier.andere ?? `${leeftijd}${typeKort}; ${leeftijd} ${typeKort}`
              );
            }),
          ),
        ].join('; ');
        augmentedLid.textfinder = `all;${lid.phoneNumber};${nonetext};${
          lid.fname
        } ${lid.lname};${lid.lname} ${
          lid.fname
        };${dierennamen};${dierenopmerkingen.join('; ')};${
          lid.opmerking
        };${actieftext};${geweesttext};${lid.email};${
          lid.situatie
        };${dierentypes}`.toLowerCase();
        return augmentedLid;
      });
  }, [dataLeden?.ledens, bedeling]);

  React.useEffect(() => {
    searchString
      ? setLeden(
          ledenBackup?.filter((x) =>
            searchString
              .split(';')
              .every((s) => x.textfinder.indexOf(s.toLowerCase()) >= 0),
          ),
        )
      : setLeden(ledenBackup?.filter((x) => x.actief));
  }, [ledenBackup, ledenBackup, searchString]);

  const listHasDeletedLeden = React.useMemo(() => {
    const ledenIdsFromData = dataLeden?.ledens.map((lid) => lid.id);
    const ledenIdsFromAanwezigheden = dataAanwezigheden?.aanwezighedens.map(
      (aanw) => aanw.lid?.id,
    );
    return (
      ledenIdsFromAanwezigheden?.filter(
        (lidId) => !ledenIdsFromData?.includes(lidId),
      ).length ?? 0
    );
  }, [dataLeden?.ledens, dataAanwezigheden]);

  const [saveLid] = useMutation(UPDATE_LID);

  const handleWaarborg = (lidID, wbValue) => {
    saveLid({
      variables: { id: lidID, data: { waarborgPotten: wbValue } },
    });
  };

  const goToNextUnreadMessage = () => {
    const unreadMessages = berichten?.filter(
      (checkBericht) =>
        !checkBericht.readByUsers
          .map((user) => user.id)
          .includes(currentUser?.id) && checkBericht.id !== bericht.id,
    );

    // find the next unread message
    const nextUnreadMessage = unreadMessages.find(
      (bericht) =>
        !bericht.readByUsers.map((user) => user.id).includes(currentUser?.id),
    );
    // if there is a next unread message, set it as the selected message
    if (nextUnreadMessage) {
      setSelectedBericht(berichten.indexOf(nextUnreadMessage));
      return;
    }
    // if there is no next unread message, close the popup
  };

  const [createMessage, { loading: loadingCreateMessage }] = useMutation(
    CREATE_BERICHTEN,
    {
      refetchQueries: [
        { query: GET_BERICHTEN },
        { query: CHECK_UNREAD_BERICHTEN },
      ],
      onError: (error) => {
        console.error(error);
      },
      onCompleted: () => {
        setShowAfsluiten(false);
      },
    },
  );

  const handleSaveInventaris = async (values, helpers) => {
    await createMessage({
      variables: {
        input: {
          title: `inventaris na bedeling ${bedelingDatum.toLocaleDateString(
            'nl-BE',
            dateOptions,
          )}`,
          type: 'toVulploeg',
          richBody: `<table>
<tr><td>Datum:</td><td colspan="3">${values.datum}</td></tr>
<tr><td>Verantwoordelijke:</td><td>${values.verantwoordelijke}</td><td>Medewerker:</td><td>${values.medewerker}</td></tr>
<tr><td colspan="3">Categorie</td><td >Aantal voorradig</td></tr>
<tr><td rowspan="9">Hond</td><td rowspan="3">Puppy</td><td>Klein</td><td>${values.kleinPuppy}</td></tr>
<tr><td>Medium</td><td>${values.mediumPuppy}</td></tr>
<tr><td>Groot</td><td>${values.grootPuppy}</td></tr>
<tr><td rowspan="3">Volwassen</td><td>Klein</td><td>${values.kleinVolwassen}</td></tr>
<tr><td>Medium</td><td>${values.mediumVolwassen}</td></tr>
<tr><td>Groot</td><td>${values.grootVolwassen}</td></tr>
<tr><td rowspan="3">Senior</td><td>Klein</td><td>${values.kleinSenior}</td></tr>
<tr><td>Medium</td><td>${values.mediumSenior}</td></tr>
<tr><td>Groot</td><td>${values.grootSenior}</td></tr>
<tr>
<td rowspan="3">Kat</td><td colspan="2">Kitten</td><td>${values.kitten}</td></tr>
<tr>
<td colspan="2">Volwassen</td><td>${values.volwassenKat}</td></tr>
<tr><td colspan="2">Senior</td><td>${values.seniorKat}</td>
</tr>
<tr><td colspan="3">Kattenzand</td><td>${values.kattenzand}</td>
<tr><td>Speciallekes</td><td colspan="3" style="white-space: pre-line;">${values.speciallekes}</td></tr>
<tr><td>Opmerkingen</td><td colspan="3  style="white-space: pre-line;"">${values.opmerkingen}</td></tr>
</table>`,
        },
      },
    });
    helpers.resetForm();
  };

  const handleClickShowMessage = () => {
    setSelectedBericht(0);
    setShowBericht(true);
  };

  return (
    <Page>
      <TitleAndButton>
        <TopTitle>
          BEDELING{' '}
          {bedelingDatum ? (
            bedelingDatum.toLocaleDateString('nl-BE', dateOptions)
          ) : (
            <ActivityIndicator />
          )}
        </TopTitle>
        <Buttons>
          <Button
            disabled={loadingLeden || loadingBedeling || loadingAanwezigheden}
            primary
            onClick={() => setShowAfsluiten(true)}>
            Inventaris opmaken
          </Button>
          {berichten && berichten.length > 0 && (
            <Button primary onClick={handleClickShowMessage}>
              Toon bericht(en)
            </Button>
          )}
        </Buttons>
      </TitleAndButton>
      <SearchBox
        setSearchString={setSearchString}
        searchString={searchString}
      />
      <TableContainer>
        <BedelingTableHeader />
        {(loadingLeden || loadingBedeling || loadingAanwezigheden) && (
          <LoadingDiv />
        )}
        {!DEBUG_SKIP_LEDEN &&
          !loadingLeden &&
          !loadingAanwezigheden &&
          !loadingBedeling &&
          leden?.map((lid) => {
            return (
              <LidRow
                key={'lid' + lid.id}
                lid={lid}
                handleWaarborg={handleWaarborg}
                bedelingId={bedeling?.id}
              />
            );
          })}
      </TableContainer>
      {listHasDeletedLeden > 0 &&
        !(loadingLeden || loadingBedeling || loadingAanwezigheden) &&
        !searchString && (
          <DeletedUserWarning>
            <i className="far fa-info-circle"></i>
            {listHasDeletedLeden > 1
              ? 'Er zijn meerdere gebruikers die aanwezig waren, maar sindsdien gewist werden uit de database'
              : 'Er is 1 gebruiker die aanwezig was, maar sindsdien gewist werd uit de database'}
          </DeletedUserWarning>
        )}
      <StyledPopupModal
        canClose={berichtCanBeHidden}
        onOutsideClick={
          berichtCanBeHidden
            ? () => {
                setShowBericht(false);
              }
            : null
        }
        visible={showBericht}>
        <MessageCounter>
          {!berichten ? (
            <ActivityIndicator />
          ) : (
            <div>
              Bericht <SelectedBericht>{selectedBericht + 1}</SelectedBericht>{' '}
              van {berichten.length}
            </div>
          )}
          {berichten && berichten.length > 1 && (
            <Arrows>
              <UnstyledButton
                onClick={() =>
                  setSelectedBericht((prev) =>
                    prev === 0 ? berichten.length - 1 : prev - 1,
                  )
                }>
                ‹
              </UnstyledButton>
              <UnstyledButton
                onClick={() =>
                  setSelectedBericht((prev) => (prev + 1) % berichten.length)
                }>
                ›
              </UnstyledButton>
            </Arrows>
          )}
        </MessageCounter>
        {bericht ? (
          <Message messageData={bericht} isBedeling />
        ) : (
          <ActivityIndicator />
        )}
        <Button
          key={`${isBerichtReadByCurrentUser}-${isAllBerichtenReadByCurrentUser}`}
          primary
          loading={loadingMarkAsRead}
          disabled={loadingMarkAsRead}
          onClick={
            isBerichtReadByCurrentUser
              ? isAllBerichtenReadByCurrentUser
                ? () => setShowBericht(false)
                : () => goToNextUnreadMessage()
              : () => markAsRead()
          }>
          {isBerichtReadByCurrentUser
            ? isAllBerichtenReadByCurrentUser
              ? 'sluit venster'
              : 'toon volgende bericht'
            : 'markeer als gelezen' +
              (hasMoreThanOneUnreadMessage ? ' en toon volgende' : '')}
        </Button>
      </StyledPopupModal>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          verantwoordelijke: currentUser?.username,
          datum: bedelingDatum?.toLocaleDateString('nl-BE', dateOptions),
        }}
        onSubmit={handleSaveInventaris}
        validationSchema={BedelingAfsluitenSchema}
        validateOnMount>
        <StyledPopupModal
          canClose
          title="INVENTARIS OPMAKEN"
          onOutsideClick={() => {
            berichtCanBeHidden && setShowAfsluiten(false);
          }}
          visible={showAfsluiten && !(showBericht || !berichtCanBeHidden)}>
          <Inventaris loading={loadingCreateMessage} />
        </StyledPopupModal>
      </Formik>
    </Page>
  );
};

export default BedelingView;
